import React from 'react';
import PropTypes from 'prop-types';

const links = {
  chrome: "https://www.google.com/chrome",
  firefox: "https://www.mozilla.org/firefox",
  edge: "https://www.microsoft.com/edge",
}

function isIE() {

  if(typeof window !== "undefined") return window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
  else return false;
}

function BrowserLink({href, children}) {

  return (
    <a 
      href={href}
      style={{
        color: "blue",
        textDecoration: "underline",
      }}
    >
      {children}
    </a>
  );
}

function IEBanner({children, customElement, replace, debug}) { 

  const defaultElement = 
  <div 
    style={{
      position: "fixed",
      zIndex: 100,
      width: "100%",
      padding: "2rem", 
      textAlign: "center",
      backgroundColor: "white",
      boxShadow: "0 0px 10px",
    }}
  >
    This website does not support Internet Explorer. Please use <BrowserLink href={links.chrome}>Chrome</BrowserLink>, <BrowserLink href={links.firefox}>Firefox</BrowserLink> or <BrowserLink href={links.edge}>Edge</BrowserLink>.
  </div>;

  return (
    <>
      {isIE() || debug ? 
        <>
          {customElement || defaultElement}
          {replace ? <></> : children}
        </>  
        : 
        children 
      }  
    </>
  );
}

IEBanner.propTypes = {
  children: PropTypes.node,
  customElement: PropTypes.node,
  replace: PropTypes.bool,
  debug: PropTypes.bool,
};

IEBanner.defaultProps = {
  replace: false,
  debug: false,
};

export default IEBanner;