import PropTypes from 'prop-types';
import React from 'react';

function Anchor({
  href, className, target, rel, children,
}) {
  return (
    <a href={href} className={className} target={target} rel={rel}>{children}</a>
  );
}

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.node.isRequired,
};

export default Anchor;