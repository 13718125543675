import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Anchor from '../../atoms/Anchor/Anchor';

const Button = ({
  internal, href, target, additionalClasses, children, style, textOnDarkBg
}) => {
  const newTab = (target === 'blank' || target === '_blank');
  const sharedClass = `mb-4 mr-4 w-full sm:w-auto text-center sm:text-left text-xs font-lato font-bold leading-3 px-12 py-4 border-2 uppercase bg-transparent transition transition-duration-500   ${textOnDarkBg ? "hover:text-black hover:bg-white hover:border-white border-white" : "hover:text-white hover:bg-brand-red hover:border-brand-red border-black"}`;

  // If it's an internal link, use Link rather than Anchor
  if (internal) {
    return (
      <>
        <Link
          to={href}
          {...(newTab ? { target: 'blank', rel: 'noopener noreferrer' } : {})}
          className={`${additionalClasses} ${sharedClass}`}
        >
          {children}
        </Link>
      </>
    );
  }
  return (
    <>
      <Anchor
        href={href}
        {...(newTab ? { target: 'blank', rel: 'noopener noreferrer' } : {})}
        className={`${additionalClasses} ${sharedClass}`}
      >
        {children}
      </Anchor>
    </>
  );
};

Button.propTypes = {
  textOnDarkBg: PropTypes.bool,
  internal: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  primary: PropTypes.bool,
  additionalClasses: PropTypes.string,
};

Button.defaultProps = {
  textOnDarkBg: true,
  primary: true,
  href: 'https://www.google.com',
  additionalClasses: '',
};

export default Button;