import * as React from "react"
import Container from "../components/atoms/Container/Container";
import IEBanner from "../components/iebanner";
import Layout from "../components/layout";
import Button from "../components/molecules/Button/Button";
import FancyHeading from "../components/molecules/FancyHeading/FancyHeading";
import SEO from "../components/seo";

const NotFoundPage = () => {
  return (  
    <>
      <SEO title="Page Not Found" />
      <IEBanner />
      <Layout>
        <Container className="bg-brand-light-grey">
          <FancyHeading center={true} darkBg="">Page Not Found</FancyHeading>
          <p className="font-lato text-lg mb-8 text-center text-center">We couldn’t find what you were looking for.</p>
          <div className="flex justify-center">
            <Button href="/" textOnDarkBg={false} primary={true}>Back To Home</Button>
          </div>
        </Container>
      </Layout>
    </>
  );
}

export default NotFoundPage

